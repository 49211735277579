import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Typography from '@mui/material/Typography'
import DoughnutChart from '../../../../components/Chart/DoughnutChart'
import { contentType } from '../enum/enum'
import { StyledButton } from '../Styled'
import WhiteCard from './WhiteCard'
import TypeCard from './TypeCard'

const ContentSummary = ({ type }) => {
  const { content } = useSelector(
    (state) => ({
      content: state.crud?.eContentDashboard?.overview?.[type],
    }),
    shallowEqual,
  )
  return (
    <Grid item xs="6">
      <Card sx={{ padding: '8px 16px 16px 16px' }}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="h6">{contentType[type].label}</Typography>
          <StyledButton
            color="text.lightGray"
            size="small"
            marginLeft=""
            background="#4976BA14"
            onClick={() => window.open(contentType[type].path, '_blank')}
          >
            <ChevronRight fontSize="small" />
          </StyledButton>
        </Box>
        <Grid container spacing={2} mt={0}>
          <Grid item xs={4.5}>
            <WhiteCard value={content?.active} label="Active" />
          </Grid>
          <Grid item xs={4.5}>
            <WhiteCard value={content?.inactive} label="Inactive" />
          </Grid>
        </Grid>
        <Box display="flex" gap={4} mt={2}>
          <DoughnutChart
            dataSet={{
              data: [
                content?.agency,
                content?.banc,
                content?.ktb,
                content?.other,
              ],
              backgroundColor: ['#32A287', '#FFE66D', '#4976BA', '#B49CE4'],
            }}
            height={176}
            label="ทั้งหมด"
            value={content?.total}
            eleId={contentType[type].chartId}
            cutout={60}
          />
          <Grid container spacing={0.5}>
            <Grid item xs={6}>
              <TypeCard type={type} channel="agency" />
            </Grid>
            <Grid item xs={6}>
              <TypeCard type={type} channel="banc" />
            </Grid>
            <Grid item xs={6}>
              <TypeCard type={type} channel="ktb" />
            </Grid>
            <Grid item xs={6}>
              <TypeCard type={type} channel="other" />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Grid>
  )
}

export default ContentSummary
