import React from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import { formatPhoneNo, formatIdCard } from '../../../../../utils/lib'

import { NewButtonAction } from '../../Document/Content/ContentTableLeft'
import { BoxLabelText } from './Styled'
import { getFormatDate } from '../../../../../utils/lib'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

export const BoxLabel = ({ labelLeft, labelRight, isIcon }) => {
  return (
    <BoxLabelText>
      <Box sx={{ width: 450 }}>
        <Typography variant="body1" color="text.secondary">
          {labelLeft}
        </Typography>
      </Box>
      {isIcon ? (
        labelRight
      ) : (
        <Typography variant="body1">{labelRight}</Typography>
      )}
    </BoxLabelText>
  )
}

const Index = () => {
  const initialState = useSelector(
    (state) => state.manageClassLearnerDetail.initialState,
  )
  const dataList = useSelector(
    (state) => state.manageClassLearnerDocument.dataList,
  )
  const user = localStorage.getItem('user')
  const { id } = useParams()
  const learnerDetail = _.get(initialState, 'learnerDetail', {})

  const name = `${_.get(learnerDetail, 'firstNameTH', '-')} ${
    _.get(learnerDetail, 'lastNameTH', '') || ''
  }`

  const listIndex = _.findIndex(dataList.document, (data) => {
    if (data.status && data.status === 'รอตรวจสอบ') return true
  })

  const documentList = _.get(
    dataList,
    `document[${listIndex}].documentList`,
    [],
  )

  return (
    <Card sx={{ mt: 2 }}>
      <Box sx={{ m: 3 }}>
        {/* personal information */}
        <Typography variant="h5" sx={{ mb: 1 }}>
          ข้อมูลส่วนตัว
        </Typography>
        <BoxLabel
          labelLeft="คำนำหน้าชื่อ"
          labelRight={_.get(learnerDetail, 'prefixTH', '-')}
        />
        <BoxLabel labelLeft="ชื่อ - นามสกุล" labelRight={name} />
        <BoxLabel
          labelLeft="เลขบัตรประชาชน"
          labelRight={formatIdCard(_.get(learnerDetail, 'idCardNo', '-'))}
        />
        <BoxLabel
          labelLeft="วัน เดือน ปี เกิด"
          labelRight={getFormatDate(
            _.get(learnerDetail, 'dateOfBirth', '-'),
            'DD MMMM BBBB',
          )}
        />
        <BoxLabel
          labelLeft="เบอร์โทรศัพท์"
          labelRight={formatPhoneNo(_.get(learnerDetail, 'mobileNo', '-'))}
        />
        <BoxLabel
          labelLeft="อีเมล"
          labelRight={_.get(learnerDetail, 'email', '-')}
        />

        {/* detail */}
        <Typography variant="h5" sx={{ mb: 1, mt: 1 }}>
          ข้อมูลการทำงาน
        </Typography>
        <BoxLabel
          labelLeft="สังกัดหน่วย"
          labelRight={_.get(learnerDetail, 'unitNumber', '-')}
        />
        <BoxLabel labelLeft="เลขใบอนุญาต" labelRight={'-'} />
        <BoxLabel
          labelLeft="รหัสตัวแทน"
          labelRight={_.get(learnerDetail, 'userCode', '-')}
        />

        {/* experience */}
        <Typography variant="h5" sx={{ mb: 1, mt: 1 }}>
          ประสบการณ์
        </Typography>
        <BoxLabel labelLeft="วุฒิการศึกษา" labelRight="-" />

        {/* document */}
        <Typography variant="h5" sx={{ mb: 1 }}>
          เอกสาร
        </Typography>
        {documentList.length > 0 &&
          documentList.map((item, index) => {
            const titleDownload = `${_.get(item, 'title', '')}_${_.get(
              learnerDetail,
              'firstNameTH',
              '-',
            )}`
            const updatedBy = _.get(item, 'updatedBy', 'null')
            const updatedByObj = JSON.parse(updatedBy)
            const footerName =
              _.get(updatedByObj, 'name', '') +
              ' ' +
              new Date(_.get(item, 'updatedAt', '')).toLocaleString()
            return (
              <>
                <Box key={index}>
                  <BoxLabel
                    isIcon={true}
                    labelLeft={_.get(item, 'title', '')}
                    labelRight={
                      <NewButtonAction
                        user={user}
                        indexList={listIndex}
                        indexDocument={index}
                        title={_.get(item, 'title', '')}
                        titleDownload={titleDownload}
                        url={_.get(item, 'key', '')}
                        footer={footerName}
                        disableDelete={true}
                        hideUpload={true}
                        disableUpload={
                          _.get(dataList, 'statusClass', '') === 'CLOSED'
                        }
                        classLearnerUuid={id}
                      />
                    }
                  />
                </Box>
              </>
            )
          })}
        {!documentList.length && (
          <BoxLabel labelLeft="ไม่มีข้อมูลเอกสาร" labelRight="" />
        )}
      </Box>
    </Card>
  )
}

export default Index
