import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import ArrowForward from '@mui/icons-material/ArrowForward'
import {
  manageClassIcon,
  manageClassTheme,
  statusTheme,
} from '../../../../constants/manageClass'
import { convertFormatDateTime, getQueryParams } from '../../../../utils/lib'
import {
  StyledStatusCard,
  StyledColoredCard,
  StyledChip,
  StyledRow,
  StyledColumn,
  StyledRightBox,
  StyledContainer,
  StyledDashboardRow,
} from './Styled'
import EventCard from './EventCard'
import ClassDetailDrawer from './ClassDetailDrawer'
import StudyPapersDrawer from './StudyPapersDrawer'
import {
  getManageClassDownloadFiles,
  getManageClassGraph,
} from '../../../../../src/services/manageClass/detail'
import { GraphCard } from './GraphCard/GraphCard'
import InventoryCard from './InventoryCard'
import { getTickets } from './InventoryCard/events'

const Dashboard = () => {
  const { id: uuid } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [toggleProps, setToggleProps] = useState({
    classOpen: false,
    studyPapersOpen: false,
  })
  const { isCancel, manageClassData } = useSelector(
    (state) => ({
      isCancel: state.manageClassDetail.isCancel,
      manageClassData: state.manageClassDetail.data,
    }),
    shallowEqual,
  )
  const fileDownloadData = useSelector(
    (state) => state.manageClassDetail.files,
    shallowEqual,
  )
  const learners = _.get(manageClassData, 'classLearner', [])

  const learnerAmount = learners.filter(
    (learner) => learner.status == 'ELIGIBLE_ANNOUNCE',
  ).length

  const registerAmonunt = learners.filter(
    (learner) =>
      learner.status !== 'CANCELED' &&
      learner.status !== 'CANCEL' &&
      learner.status !== 'INELIGIBLE' &&
      learner.status !== 'ELIGIBLE_ANNOUNCE' &&
      learner.status !== 'ABSENT' &&
      learner.status !== 'REJECTED_DOCUMENT' &&
      learner.status !== 'CANCEL_CLASS',
  ).length

  const startRegisterDateTime = _.get(
    manageClassData,
    'startRegisterDateTime',
    '',
  )
  const endRegisterDateTime = _.get(manageClassData, 'endRegisterDateTime', '')
  const isSetRegisterDate = startRegisterDateTime !== endRegisterDateTime
  const monthlyPlan = _.get(manageClassData, 'monthlyPlan', '')
  const monthlyPlanUuid = _.get(monthlyPlan, 'uuid', '')
  const trainingInfo = _.get(monthlyPlan, 'trainingInfo[0]', '')
  const course = _.get(trainingInfo, 'course', '')
  const courseUuid = _.get(course, 'uuid', '')
  const courseUrl = `/course-setting/version/preview/${courseUuid}`
  const learnerUrl = `/manage-class/learner/${uuid}`
  const attendanceUrl = `/manage-class/attendance/${uuid}`
  const isAttendance = _.get(course, 'isAttendance', false)
  const status = _.get(manageClassData, 'status', '')
  const startDate = convertFormatDateTime({
    value: _.get(monthlyPlan, 'startDate', new Date()),
    type: 'date',
  })
  const finishDate = convertFormatDateTime({
    value: _.get(monthlyPlan, 'finishDate', new Date()),
    type: 'date',
  })
  const area = _.get(monthlyPlan, 'area', undefined)
  const documentAmount = _.get(fileDownloadData, 'electronic', []).length
  const isStatusDisable = status == 'UNASSIGN' || status == 'TODO'
  const objOnLink = {
    drawer: Number(getQueryParams('drawer')),
  }

  useEffect(() => {
    dispatch(getManageClassGraph(uuid))
    if (courseUuid) {
      dispatch(getManageClassDownloadFiles(courseUuid))

      if (_.get(objOnLink, 'drawer', 0) == 1) {
        setToggleProps((val) => ({ ...val, classOpen: true }))
      }
    }
  }, [courseUuid])
  useEffect(() => {
    if (monthlyPlanUuid) {
      dispatch(getTickets(monthlyPlanUuid))
    }
  }, [monthlyPlanUuid])

  return (
    <StyledContainer>
      <StyledColumn sx={{ gap: 2 }}>
        {/* // Hide Inventory */}
        {window.__env__.ENV !== 'PROD' ? (
          <StyledDashboardRow>
            <StatusCard field={status} />
            <InventoryCard />
          </StyledDashboardRow>
        ) : (
          <StatusCard field={status} />
        )}
        <StyledDashboardRow>
          <MiniColoredCard
            field="learner"
            title="ผู้มีสิทธ์เข้าเรียน / ผู้สมัคร"
            onClick={() => history.push(learnerUrl)}
            isDisable={isStatusDisable}
          >
            <Typography variant="body1">{`${learnerAmount} / ${registerAmonunt} คน`}</Typography>
          </MiniColoredCard>
          <MiniColoredCard
            field="userCheck"
            title="เช็คชื่อ"
            onClick={() => history.push(attendanceUrl)}
            isDisable={isStatusDisable || isCancel || !isAttendance}
          >
            <StyledChip
              label={`กำหนด ${_.get(course, 'attendanceCount', 0)} ครั้ง/วัน`}
            />
          </MiniColoredCard>
          <MiniColoredCard
            field="document"
            title="เอกสารการเรียน"
            onClick={() =>
              setToggleProps((val) => ({ ...val, studyPapersOpen: true }))
            }
            isDisable={documentAmount == 0}
          >
            <Typography variant="body1">{documentAmount} ไฟล์</Typography>
          </MiniColoredCard>
        </StyledDashboardRow>
        <StyledDashboardRow>
          <ColoredCard
            field="courseDetail"
            title="รายละเอียดหลักสูตร"
            onClick={() => history.push(courseUrl)}
          >
            <LabelAndData
              label="รูปแบบการสอน"
              data={_.get(manageClassData, 'courseCode', '-')}
            />
            <LabelAndData
              label="ช่องทางการจำหน่าย"
              data={_.get(course, 'distribution.distribution', '-')}
            />
            <LabelAndData
              label="กลุ่มหลักสูตร"
              data={_.get(course, 'roadmap.title', '-')}
            />
          </ColoredCard>
          <ColoredCard
            sx={{ width: '66.66%', minWidth: 512 }}
            field="classDetail"
            title="รายละเอียดคลาส"
            onClick={() =>
              setToggleProps((val) => ({ ...val, classOpen: true }))
            }
          >
            <StyledRow>
              <LabelAndData
                label="รูปแบบการสอน"
                data={_.get(
                  course,
                  'trainingPlatform[0].trainingPlatform',
                  '-',
                )}
              />
              <LabelAndData
                label="Station"
                data={_.get(area, 'station.station', '-')}
              />
            </StyledRow>
            <StyledRow>
              <LabelAndData
                label="วันเปิดสมัคร"
                data={isSetRegisterDate ? startRegisterDateTime : '-'}
              />
              <LabelAndData
                label="วันปิดรับสมัคร"
                data={isSetRegisterDate ? endRegisterDateTime : '-'}
              />
            </StyledRow>
            <StyledRow>
              <LabelAndData label="วันเปิดอบรม" data={startDate} />
              <LabelAndData label="วันปิดอบรม" data={finishDate} />
            </StyledRow>
          </ColoredCard>
        </StyledDashboardRow>
      </StyledColumn>
      <StyledRightBox status={status}>
        {(status === 'ENDCLASS' || status === 'CLOSED') && (
          <GraphCard status={status} />
        )}
        <EventCard />
      </StyledRightBox>

      <ClassDetailDrawer
        isOpen={toggleProps.classOpen}
        uuid={uuid}
        onCloseDrawer={() =>
          setToggleProps((val) => ({ ...val, classOpen: false }))
        }
      />
      <StudyPapersDrawer
        isOpen={toggleProps.studyPapersOpen}
        onCloseDrawer={() =>
          setToggleProps((val) => ({ ...val, studyPapersOpen: false }))
        }
      />
    </StyledContainer>
  )
}

export default Dashboard

export const StatusCard = ({ field }) => {
  return (
    <StyledStatusCard field={field}>
      <Typography
        variant="h6"
        color={_.get(statusTheme[field], 'color', 'primary')}
      >
        {_.get(statusTheme[field], 'title', '-')}
      </Typography>
      <img src={_.get(statusTheme[field], 'image', '-')} />
    </StyledStatusCard>
  )
}

export const MiniColoredCard = (props) => {
  const { field, title, children, sx, onClick, isDisable } = props
  return (
    <StyledColoredCard field={field} sx={sx}>
      <Divider orientation="vertical" />
      <img src={manageClassIcon[field]} width={28} height={28} />
      <Typography variant="body1b">{title}</Typography>
      {children}
      <IconButton
        data-testid={`btn-${field}`}
        size="small"
        color={manageClassTheme[field].icon}
        onClick={onClick}
        disabled={isDisable}
      >
        <ArrowForward />
      </IconButton>
    </StyledColoredCard>
  )
}

export const ColoredCard = (props) => {
  const { field, title, children, sx, onClick } = props
  return (
    <StyledColoredCard
      field={field}
      sx={{ ...sx, flexDirection: 'row', gap: 1.5 }}
    >
      <Divider orientation="vertical" />
      <img src={manageClassIcon[field]} width={28} height={28} />
      <Box
        sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}
      >
        <Typography variant="body1b">{title}</Typography>
        {children}
        <IconButton
          data-testid={`btn-${field}`}
          size="small"
          color={manageClassTheme[field].icon}
          onClick={onClick}
        >
          <ArrowForward />
        </IconButton>
      </Box>
    </StyledColoredCard>
  )
}

export const LabelAndData = ({ label, data }) => {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography
        variant="caption"
        color="text.gray"
        sx={{ lineHeight: '18px' }}
      >
        {label}
      </Typography>
      <Typography sx={{ lineHeight: '24px' }}>{data}</Typography>
    </Box>
  )
}
