import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { Row } from 'src/components/Layout/Styled'

const AllContent = () => {
  const { total } = useSelector(
    (state) => ({
      total: state.crud?.eContentDashboard?.overview?.total,
    }),
    shallowEqual,
  )
  return (
    <Card sx={{ maxWidth: 226, padding: '8px 16px 0 16px' }}>
      <Typography variant="h6">ยอดรวม Content ทั้งหมด</Typography>
      <Row>
        <Typography variant="h4" mr={0.5}>
          {total}
        </Typography>
        <Typography variant="body2" color="text.lightGray">
          Content
        </Typography>
      </Row>
    </Card>
  )
}

export default AllContent
