import React from 'react'
import _ from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import Card from '@mui/material/Card'

import ContentTableLeft from './ContentTableLeft'
import ContentTableRight from './ContentTableRight'
import { BoxContent } from './Styled'
import './table.css'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

const Index = () => {
  const { dataList, documentBody } = useSelector(
    (state) => ({
      dataList: state.manageClassLearnerDocument.dataList,
      documentBody: state.manageClassLearnerDocument.documentBody,
    }),
    shallowEqual,
  )

  const trainingPlanUuid = dataList.trainingPlanUuid
  const { id } = useParams()
  const user = localStorage.getItem('user')
  const document = _.orderBy(_.get(dataList, 'document', []), ['id'], ['desc'])
  const lastDocument = document.find((item) => item.status === 'ผ่านการตรวจสอบ')
  const isApprovedDocument = _.get(dataList, 'isApprovedDocument', false)
  const statusClass = _.get(dataList, 'statusClass', '')

  return (
    <Card sx={{ width: '100%', mt: 2 }}>
      {document.map((item, index) => {
        return (
          <BoxContent key={_.get(item, 'round', 0)}>
            <ContentTableLeft
              user={user}
              listIndex={index}
              documentData={document}
              documentList={_.get(item, 'documentList', [])}
              documentBody={documentBody}
              round={isApprovedDocument ? 0 : _.get(item, 'round', 0)}
              roundLabel={_.get(item, 'round', 0)}
              roundStatus={_.get(item, 'status', '')}
              lastDocument={lastDocument}
              name={_.get(dataList, 'firstNameTH', '')}
              isApprovedDocument={isApprovedDocument}
              classLearnerDocumentUuid={item.uuid}
              trainingPlanUuid={trainingPlanUuid}
              statusClass={statusClass}
              uploadRound={item.round}
              classLearnerUuid={id}
            />
            <ContentTableRight item={item} />
          </BoxContent>
        )
      })}
    </Card>
  )
}

export default Index
