import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Row } from 'src/components/Layout/Styled'
import { channelEnum } from '../enum/enum'
import { Dot } from '../Styled'

const TypeCard = ({ type, channel }) => {
  const { content } = useSelector(
    (state) => ({
      content: state.crud?.eContentDashboard?.overview?.[type],
    }),
    shallowEqual,
  )
  return (
    <Box
      sx={{
        position: 'relative',
        background: channelEnum?.[channel]?.bgColor,
        padding: '10px 10px 10px 26px',
        borderRadius: '8px',
      }}
    >
      <Dot background={channelEnum?.[channel]?.dotColor} />
      <Typography variant="body2b">{channelEnum?.[channel]?.label}</Typography>
      <Row>
        <Typography sx={{ lineHeight: '20px' }} variant="h4" mr={3}>
          {content?.[channelEnum?.[channel]?.value]}
        </Typography>
        <Typography
          sx={{ lineHeight: '18px' }}
          variant="body2"
          color="text.lightGray"
        >
          Content
        </Typography>
      </Row>
      <Typography
        sx={{ lineHeight: '18px' }}
        variant="body2"
        color="text.lightGray"
      >
        {content?.[channelEnum?.[channel]?.percent]}
      </Typography>
    </Box>
  )
}

export default TypeCard
