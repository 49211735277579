import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import InboxTwoTone from '@mui/icons-material/InboxTwoTone'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import _ from 'lodash'

const TableSRAM = ({ sramName = '', data = [] }) => {
  const theme = useTheme()
  const dataList = data
  const TOTAL = _.sumBy(data, 'TOTAL')
  const MANAGER = _.sumBy(data, 'MANAGER')
  const AGENT = _.sumBy(data, 'AGENT')
  const TERMINATE = _.sumBy(data, 'TERMINATE')
  return (
    <StyledFullWidth
      sx={{
        mx: theme.spacing(3),
        mt: theme.spacing(3),
        // isLoading: isLoading,
      }}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{
            minWidth: 250,
          }}
          aria-label="simple table"
        >
          <TableHead sx={{ maxWidth: 250 }}>
            <TableRow>
              <TableCell sx={{ width: '50px' }}>ลำดับ</TableCell>
              <TableCell sx={{ width: '100px' }}>
                {sramName ?? 'SRAM'}:
              </TableCell>
              <TableCell sx={{ width: '250px' }}>Total</TableCell>
              <TableCell sx={{ width: '150px' }}>Manager</TableCell>
              <TableCell sx={{ width: '150px' }}>Agent</TableCell>
              <TableCell sx={{ width: '150px' }}>Terminate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((row, rowIndex) => {
              return (
                <TableRow
                  key={`sramName-${row.ramName}`}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{rowIndex + 1}</TableCell>
                  <TableCell>{row.ramName}</TableCell>
                  <TableCell>{row.TOTAL}</TableCell>
                  <TableCell>
                    {row.AGENT}/{row.TOTAL} (
                    {row.AGENT > 0
                      ? ((row.AGENT / row.TOTAL) * 100).toFixed(2)
                      : 0}
                    %)
                  </TableCell>
                  <TableCell>
                    {row.MANAGER}/{row.TOTAL} (
                    {row.MANAGER > 0
                      ? ((row.MANAGER / row.TOTAL) * 100).toFixed(2)
                      : 0}
                    %)
                  </TableCell>
                  <TableCell>
                    {row.TERMINATE}/{row.TOTAL} (
                    {row.TERMINATE > 0
                      ? ((row.TERMINATE / row.TOTAL) * 100).toFixed(2)
                      : 0}
                    %)
                  </TableCell>
                </TableRow>
              )
            })}
            {dataList.length === 0 && (
              <TableRow>
                <StyledTableBodyCell colSpan={6}>
                  <Box>
                    <InboxTwoTone />
                    <Typography variant="body2" color="text.lightGray">
                      ไม่พบข้อมูล
                    </Typography>
                  </Box>
                </StyledTableBodyCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <strong>Total</strong>
              </TableCell>
              <TableCell>
                <strong>{TOTAL}</strong>
              </TableCell>
              <TableCell>
                <strong>
                  {MANAGER}/{TOTAL} (
                  {MANAGER > 0 ? ((MANAGER / TOTAL) * 100).toFixed(2) : 0}
                  %)
                </strong>
              </TableCell>
              <TableCell>
                <strong>
                  {AGENT}/{TOTAL} (
                  {AGENT > 0 ? ((AGENT / TOTAL) * 100).toFixed(2) : 0}
                  %)
                </strong>
              </TableCell>
              <TableCell>
                <strong>
                  {TERMINATE}/{TOTAL} (
                  {TERMINATE > 0 ? ((TERMINATE / TOTAL) * 100).toFixed(2) : 0}
                  %)
                </strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </StyledFullWidth>
  )
}

export default TableSRAM

export const StyledTableBodyCell = styled((props) => (
  <TableCell key="no-data" align="center" {...props} />
))(({ theme }) => ({
  '& > .MuiBox-root': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& .MuiSvgIcon-root': {
      color: theme?.palette?.text?.silver,
      width: '27px',
      height: '27px',
    },
  },
}))

export const StyledFullWidth = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isLoading',
})(({ isLoading }) => ({
  width: 'auto',
  opacity: isLoading ? 0.4 : 'unset',
  pointerEvents: isLoading ? 'none' : 'unset',
}))
