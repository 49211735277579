import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import PushPinTwoToneIcon from '@mui/icons-material/PushPinTwoTone'
import AllContent from './AllContent'
import ContentSummary from './ContentSummary'
import CatSummary from './CatSummary'
import PinnedCard from './PinnedCard'

const index = () => {
  return (
    <Box>
      <Box>
        <AllContent />
      </Box>
      <Grid container spacing={3} mt={0}>
        <ContentSummary type="eContent" />
        <ContentSummary type="kb" />
      </Grid>
      <Box mt={3}>
        <Card sx={{ padding: '8px 16px 16px 16px' }}>
          <Typography variant="h6">เกี่ยวกับหมวดหมู่</Typography>
          <CatSummary type="category" />
          <Divider sx={{ margin: '16px 0' }} />
          <CatSummary type="subCategory" />
        </Card>
      </Box>
      <Box mt={3}>
        <Card sx={{ padding: '8px 16px 16px 16px' }}>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h6">เนื้อหาที่ถูก Pin</Typography>
            <PushPinTwoToneIcon color="action" />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <PinnedCard type="eContent" />
            </Grid>
            <Grid item xs={6}>
              <PinnedCard type="kb" />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Box>
  )
}

export default index
